import {useStoryblokApi, storyblokEditable} from "@storyblok/react";
import {useState, useEffect, forwardRef} from "react";
import "./CocktailPage.css";

const CocktailPage = ({blok}) => {
    const storyblokApi = useStoryblokApi();
    const [ingredientData, setIngredientData] = useState([]);

    useEffect(() => {
        storyblokApi.get('cdn/stories', {
            version: 'draft',
            starts_with: 'ingredients',
        })
            .then((response) => {
                setIngredientData(response.data.stories);
            })
            .catch((error) => {
                console.error("Error fetching stories:", error);
            });
    }, [storyblokApi]);

    const getIngredientNameById = (id) => {
         const ingredient = ingredientData.find((item) => item["uuid"] === id);
         return ingredient ? ingredient['content'].Name : "Unknown Ingredient";
    };

    return (
        <main {...storyblokEditable(blok)} className="cocktail-page">
            {/* Image */}
            {blok.Image && blok.Image.filename && (
                <div className="image-wrapper">
                    <img
                        src={blok.Image.filename}
                        alt={blok.Image.alt || "Cocktail Image"}
                        className="cocktail-image"
                    />
                </div>
            )}

            {/* Cocktail Name */}
            <h1 className="cocktail-name">{blok.Name || "Cocktail Name"}</h1>

            {/* Ingredients */}
            {blok.Ingridients && blok.Ingridients.length > 0 && (
                <div className="ingredients">
                    <h2>Ingredients</h2>
                    <ul>
                        {blok.Ingridients.map((ingredient) => (
                            <li key={ingredient._uid} className="ingredient-item">
                                {ingredient.Amount} {ingredient.AmountType} -{" "}
                                <b>{getIngredientNameById(ingredient.Ingridient)}</b>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Grid for Text Fields */}
            <div className="info-grid">
                {/* Type */}
                {blok.Type && (
                    <div className="info-box">
                        <h3>Type</h3>
                        <p>{blok.Type}</p>
                    </div>
                )}
                {/* Recipe */}
                {blok.Recipe && (
                    <div className="info-box">
                        <h3>Recipe</h3>
                        <p>{blok.Recipe}</p>
                    </div>
                )}
                {/* Glass */}
                {blok.Glass && (
                    <div className="info-box">
                        <h3>Glass</h3>
                        <p>{blok.Glass}</p>
                    </div>
                )}

                {/* Notes */}
                {blok.Notes && (
                    <div className="info-box">
                        <h3>Notes</h3>
                        <p>{blok.Notes}</p>
                    </div>
                )}

                {/* Taste */}
                {blok.Taste && (
                    <div className="info-box">
                        <h3>Taste</h3>
                        <p>{blok.Taste}</p>
                    </div>
                )}
            </div>
        </main>
    );
};

export default CocktailPage;
