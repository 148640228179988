import { useState, useEffect } from "react";
import { useStoryblokApi } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import './CocktailList.css';

const CocktailList = ({ blok }) => {
    const [cocktails, setCocktails] = useState([]);
    const storyblokApi = useStoryblokApi();

    useEffect(() => {
        storyblokApi.get('cdn/stories', {
            version: 'draft',
            starts_with: 'cocktails',
        })
            .then((response) => {
                setCocktails(response.data.stories);
            })
            .catch((error) => {
                console.error("Error fetching stories:", error);
            });
    }, [storyblokApi]);

    return (
        <div {...storyblokEditable(blok)} className="cocktail-list">
            <h2 className={"list-title"} style={{ textAlign: "center" }}>Cocktail List</h2>
            <div className="cocktail-grid">
                {cocktails.map((cocktail) => (
                    <div className="cocktail-item" key={cocktail.id}>
                        <a href={`/${cocktail.full_slug}`} className="cocktail-link">
                            <img
                                src={cocktail.content.Image.filename}
                                alt={cocktail.name}
                                className="cocktail-image"
                            />
                            <h3 className="cocktail-title">{cocktail.name}</h3>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CocktailList;
